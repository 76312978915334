import details from './details'
import search from './search'
import addCartAfterSbcEnter from './add-cart-after-sbc-enter'
import goodsListClickAfterSbcEnter from './goods-list-click-after-sbc-enter'
import informationFlow from './informationFlow'
import topTrend from './topTrend'
import trendLanding from './trendLanding'
import clickRefresh from './clickRefresh'
import store from './store'
import searchAssociationWords from './search-association-words'

export default [
  details,
  search,
  addCartAfterSbcEnter,
  goodsListClickAfterSbcEnter,
  informationFlow,
  topTrend,
  trendLanding,
  clickRefresh,
  store,
  searchAssociationWords
]
