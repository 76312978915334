
//google自动登录冷却期缓存
export const DISABLE_AUTO_SELECT = 'disableAutoSelect'

//google自动登录冷却时长24小时
export const DISABLE_AUTO_SELECT_TIME = 24 * 3600 * 1000


export const USERINFO_KEY = 'auth_member'


export const KOL_SECRET = 'ajhjkjalh778ah3kjnksSDHJsaAFkjr432jsa' 
