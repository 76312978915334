import { DETAIL_PAGE_PATHS, STORE_PAGE_PATHS } from 'public/src/pages/product_app/router/constants.js'

const handleClickAddBag = (data) => {
  return {
    goods_id: Number(data?.activity_param?.goods_id || ''),
    timestamp: Number(data?.timestamp?.slice?.(0, 10) || ''),
  }
}

const handleClickGoodsList = (data) => {
  return {
    goods_id: Number((data?.activity_param?.goods_list || '')?.split('`')?.[0] || ''),
    timestamp: Number(data?.timestamp?.slice?.(0, 10) || ''),
  }
}


const config = {
  featureKey: 'page_store', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: true, // 是否使用 sessionStorage 存储数据
  saNames: [
    {
      saName: 'click_goods_list',
      limit: 40
    },
    {
      saName: 'click_add_bag',
      limit: 40
    },
  ], 
  // 所经过的埋点name
  targetNames: [
    {
      name: 'page_store',
      url: `/${STORE_PAGE_PATHS.join('|')}`
    },
    {
      name: 'page_goods_detail',
      url: `/${DETAIL_PAGE_PATHS.join('|')}`,
    },
  ],
  clearDataNames: [
    {
      name: 'page_store',
      url: `/${STORE_PAGE_PATHS.join('|')}`
    }
  ],
  handleAction(data) { // 过滤数据, 每次埋点都会触发
    const { page_param = {} } = window.SaPageInfo
    // 通过路由信息判断
    const isFromPageStore = (page_param?.src_identifier || '').includes('store=')
    
    const { activity_name, activity_param, page_name } = data
    // 仅处理店铺页点击商品、加车和从店铺页进入商详的加车
    if (
      !((page_name === 'page_store' && ['click_add_bag', 'click_goods_list'].includes(activity_name))
      ||
      (page_name === 'page_goods_detail' && isFromPageStore && activity_name === 'click_add_bag' && activity_param?.activity_from === 'main' && activity_param?.result === '1'))
    ) {
      return false
    }
    switch (activity_name) {
      case 'click_goods_list':
        return handleClickGoodsList(data)
      case 'click_add_bag':
        return handleClickAddBag(data)
      default:
        return false
    }
  },
  onTrigger({ collectData }) {
    const { click_goods_list, click_add_bag } = Object.values(collectData)[0] || {}
    const rt_f_list_cur_clk = [...(click_goods_list || [])]?.reverse?.() || []
    const rt_f_list_cur_cart = [...(click_add_bag || [])]?.reverse?.() || []
    return {
      rt_f_list_cur_clk,
      rt_f_list_cur_cart,
    }
  }
}

export default config
