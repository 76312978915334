function log(msg, data = null) {
  // eslint-disable-next-line no-console
  console.log(
    `%c [userActionTracker] %c ${msg}`,
    'color: #fff; background: #6dc260;font-size: 10px; padding: 2px;',
    'color: #333; background: #fff; font-size: 10px;padding: 2px;',
    data,
  )
}

function collectDataAssign(data, onceCurrentData) {
  return Object.keys(data).reduce((acc, sessionId) => {
    acc[sessionId] = data[sessionId]
    const oneceDataCurrentItem = onceCurrentData[sessionId]
    if (!oneceDataCurrentItem) return acc
    const dataItem = data[sessionId] || {}

    const oneIitemData = Object.keys(oneceDataCurrentItem).reduce((acc, activity_name) => {
      const _dataItem = dataItem[activity_name] || []
      const _onceDataItem = oneceDataCurrentItem[activity_name] || []
      const result = [..._dataItem, ..._onceDataItem]
      const map = new Map() // 去重
      acc[activity_name] = result.filter(item => {
        if (map.has(item.activity_param?._actionId)) return false
        map.set(item.activity_param?._actionId, true)
        return true
      })
      return acc
    }, {})
    
    acc[sessionId] = {
      ...dataItem,
      ...oneIitemData
    }
    return acc
  }, {})
}

export { log, collectDataAssign }
