const SEARCH_KEYWORDS_SA_NAME = 'click_top_site_search,click_list_top_navigation'
const PAGE_VIEW_SA_NAME = 'page_view'

const handleFromPage4SearchKeywords = (data, collectData) => {
  const isSearchKeywords = SEARCH_KEYWORDS_SA_NAME.split(',').includes(data?.activity_name)
  if (!isSearchKeywords) return 'next'

  const isClickTopNav = data?.activity_name === 'click_list_top_navigation'
  if (isClickTopNav && !data?.activity_param?.top_navigation_query) return null

  const cycleId = Object.keys(collectData)[0]
  const clickTopSiteSearch = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  const pvArr = collectData?.[cycleId]?.[PAGE_VIEW_SA_NAME] || []
  const startCyclePageName = collectData?.[cycleId]?._fromPv?.page_name
  const words_id = (data?.activity_param?.result_content?.split('`') || [])[7] || 0
  const filterData = {
    activity_name: data?.activity_name,
    page_name: data?.page_name,
    activity_param: {
      top_navigation_query: data?.activity_param?.top_navigation_query || undefined,
      search_content: data?.activity_param?.search_content || undefined,
      words_id: words_id === '-' ? 0 : words_id,
    },
    page_param: {
      pagefrom: data?.page_param?.pagefrom
    },
  }

  // cycle开始的时候
  if (!clickTopSiteSearch?.length) {
    filterData.processedEntryFrom = startCyclePageName
    return filterData
  }

  // 在一个cycle内
  const exclueLeavePvArr = pvArr.filter(item => !item.end_time) // 过滤掉离开的pv
  let entryFromIndex = exclueLeavePvArr.length - 2
  // 不是在预搜页点击的搜索按钮（目前在商详和列表 可以直接点击搜索框的搜索按钮）
  if (filterData?.page_name !== 'page_pre_search') {
    entryFromIndex = exclueLeavePvArr.length - 1
  }
  filterData.processedEntryFrom = exclueLeavePvArr[entryFromIndex]?.page_name

  return filterData
}

const handleSearchKeywords = (collectData, cycleId) => {
  const clickTopSiteSearchInfo = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  if (clickTopSiteSearchInfo?.length) {
    const cycleKeywordsInfo = clickTopSiteSearchInfo.reduce(
      (acc, cur) => {
        // 点击图文转query
        if (cur.activity_name === 'click_list_top_navigation') {
          if (cur.activity_param?.top_navigation_query) {
            const topNavigationQuery = cur?.activity_param?.top_navigation_query.split('`')
            const searchedKeywords = decodeURI(cur?.page_param?.pagefrom?.split('`')?.[2]?.split('>').join(' ').replace(',', ' ') || '')
            const topNavigationWords = topNavigationQuery[topNavigationQuery?.length - 2]
            // 倒序（新的行为放前面）
            acc.keywords.unshift(`${searchedKeywords} ${topNavigationWords}`)
            acc.wordIds.unshift(0) //  图文导航没有 words_id
          }
          return acc
        }

        // 倒序（新的行为放前面）
        acc.keywords.unshift(cur?.activity_param?.search_content?.replace?.(',', ' '))
        acc.wordIds.unshift(cur?.activity_param?.words_id || 0)

        return acc
      },
      { keywords: [], wordIds: [] }
    )

    return cycleKeywordsInfo
  }

  return { keywords: [], wordIds: [] }
}


const config = {
  featureKey: 'search_association_words_cycle', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: false,
  openBeforeStartRouterClear: true,
  saNames: [
    {
      saName: SEARCH_KEYWORDS_SA_NAME,
      limit: 5
    },
  ], // 所经过的埋点name
  targetNames: [
    {
      name: 'pre_search',
      url: '/presearch'
    },
    {
      name: 'product-list-v2',
      url: '/pdsearch/.+'
    },
  ], // 从那些页面开始记录
  clearDataNames: [
    {
      name: 'config_index',
      url: '/'
    }
  ],
  handleAction(data, collectData) { // 过滤数据, 每次埋点都会触发
    // 处理搜索词的来源页面
    const processedDataFromSearchkeywords = handleFromPage4SearchKeywords(data, collectData)
    if (processedDataFromSearchkeywords !== 'next') return processedDataFromSearchkeywords
    return data
  },
  onTrigger({ collectData }) {
    const cycleId = Object.keys(collectData)[0] // 时间戳 作为 cycleId
    const cycleKeywordsInfo = handleSearchKeywords(collectData, cycleId) // 搜索词
    
    return {
      search_cycle: {
        rt_f_search_cycle_keywords: cycleKeywordsInfo.keywords.join(),
        rt_f_search_cycle_keywords_word_id: cycleKeywordsInfo.wordIds.join(),
      }
    }
  }
}

export default config
