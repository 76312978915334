export const LIST_PAGE_PATHS = [
  'pdsearch/.+',
  'daily-new\\.html',
  '.+-v?c-\\d+\\.html',
  '.+-sc-\\w+\\.html',
  'sellingpoint-\\d+-\\d+-.+\\.html',
]

export const DETAIL_PAGE_PATHS = [
  '/*-p-(\\d+)-cat-(\\d+).html',
  '/*-p-(\\d+).html',
  '/*-p-(\\d+)-cat-(|).html',
]

export const STORE_PAGE_PATHS = [
  'store/home',
  '\.+-store-\\d{10}.html' // store reg
]
