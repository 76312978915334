export default {
  featureKey: 'goods-list-click-follow-sbc-enter', // 特征 key
  closeSessionStorage: true, // 是否使用 sessionStorage 存储数据
  singleKey: true, // 单个 sessionId
  saNames: [
    {
      // 事件名称，多个事件名称用逗号隔开
      // 记录点击列表商品
      saName: 'click_goods_list',
      limit: 1  // 限制存储的数量
    },
  ],
  handleAction() {
    return '1'
  },
  onTrigger({ collectData }) {
    const collectedMap = Object.values(collectData)[0]
    const value = collectedMap?.['click_goods_list']?.[0] ?? '0'
    return value
  }
}

// 用法 demo:
// import { handleStartCollectData, handleEndCollectData } from 'public/src/pages/common/userActionTracker/core/index.js'
// handleStartCollectData(<featureKey>) // 开始收集数据
// const value = handleEndCollectData(<featureKey>)  // 结束收集数据
